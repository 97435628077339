
import BlockTitle from '../private/BlockTitle/BlockTitle.vue'
import YoutubeEmbed from '../private/YoutubeEmbed'

export default {
  components: {
    YoutubeEmbed,
    BlockTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    youtubeId: {
      type: String,
      required: true,
    },
  },
}
